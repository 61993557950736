import React from "react";

function OnlineTutorials(){

    let menus = [
        {menuTitle:'abc', id:1},
        {menuTitle:'xyz', subMenu: {subMenuTitle:'submenu1', subid:1}, id:2}
    ];

    return(
        
        <div>OnlineTutorials</div>
    )
}

export default OnlineTutorials;

