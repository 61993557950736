// components/ComingSoon.js

import React from 'react';
import { Helmet } from 'react-helmet';

const ComingSoon = () => {
  return (
    <>
             <Helmet>
        {/* Set the title dynamically */}
        <title>Coming Soon - LetsMakeItOnline</title>
        {/* Set the meta description */}
        <meta name="title" content="Comming Soon - LetsMakeItOnline" />
        <meta name="description" content="This Page is comming soon" />
      </Helmet>

      <div className="coming-soon-container mt-5 mb-5">
  <h2>This Page is Coming Soon!</h2>
  <p>We are currently working on bringing you exciting content to enhance your experience. Stay tuned for the launch!</p>
  <p>Follow us on social media for updates:</p>
  <div className="social-icons">
    {/* Add social media icons or links here */}
    {/* Example: <i className="fab fa-twitter"></i> */}
  </div>
</div>
    </>
  
  );
};

export default ComingSoon;
